<template>
  <div id="box">
    <Header> 设置 </Header>

    <div class="box">
      <div class="box_one" @click="portrait">
        <div class="one_datum">头像</div>
        <div class="datum">
          <img class="datum_head" :src="anyUserinfo.headImg" alt="" />
          <img
            class="datum_img"
            src="../../../../static/image/right.png"
            alt=""
          />
        </div>
      </div>

      <div class="box_one" @click="datum">
        <div class="one_datum">昵称</div>
        <div class="datum">
          <span>{{ anyUserinfo.nickName }}</span>
          <img
            class="datum_img"
            src="../../../../static/image/right.png"
            alt=""
          />
        </div>
      </div>
      <div class="box_one" @click="show = true">
        <div class="one_datum">性别</div>
        <div class="datum">
          <span>{{ anyUserinfo.sex == 2 ? '女' : '男' }}</span>
          <img
            class="datum_img"
            src="../../../../static/image/right.png"
            alt=""
          />
        </div>
      </div>
      <div class="box_one" @click="binding">
        <div class="one_datum">绑定第三方账户</div>
        <div class="datum">
          <img
            class="datum_img"
            src="../../../../static/image/right.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 修改头像 -->
    <van-overlay class="wrapper" @click="head">
      <div @click.stop class="head">
        <div class="head_text">从相册中选择</div>
        <div class="head_text">相机</div>
        <div class="head_text">保存图像</div>
        <div>取消</div>
      </div>
    </van-overlay>

    <!-- 昵称修改遮罩层 -->
    <van-overlay class="mask" @click="wrapper">
      <div class="modification" @click.stop>
        <div class="modification_name">修改昵称</div>
        <div class="modification_original">
          <div class="original_name">原昵称：</div>
          <div class="original_text">{{ anyUserinfo.nickName }}</div>
        </div>
        <div class="input">
          <input
            type="text"
            v-model="nickName"
            placeholder="请输入您的新昵称"
          />
        </div>
        <div class="modification_but">
          <div class="but_cancel" @click="nameDie">取消</div>
          <div class="but_confirm" @click="name">确定</div>
        </div>
      </div>
    </van-overlay>

    <!-- 性别选择 -->
    <van-overlay :show="show" @click="show = false">
      <div class="sex" @click.stop>
        <van-picker
          title="选择性别"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="canCel"
        >
          <!-- @cancel="onCancel" -->
        </van-picker>
      </div>
    </van-overlay>
    <input
      type="file"
      ref="file"
      accept="image/*"
      hidden
      @change="selectFile"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import { Overlay, Toast } from 'vant';

Vue.use(Overlay);
export default {
  data() {
    return {
      show: false,
      nickName: '',
      anyUserinfo: [],
      columns: ['男', '女'],
    };
  },
  mounted() {
    let params = {
      token: localStorage.getItem('token'),
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.anyUserinfo = res.data;
        // window.location.href = res.data.originalUrl;
      }
    });
  },
  methods: {
    //修改性别
    onConfirm(value) {
      let params = {
        sex: value == '男' ? 1 : 2,
        token: localStorage.getItem('token'),
      };
      this.$api.anyUserinfoedit(params).then((res) => {
        if (res.code == 0) {
          this.$toast.success('修改成功');
          location.reload();
        }
      });
    },
    canCel() {
      this.show = false;
    },
    // 绑定第三方
    binding() {
      this.$router.push('/Binding');
    },
    // 修改头像
    portrait() {
      // console.log(this.$refs.file);
      this.$refs.file.click();
      // Toast('修改头像，暂缓开通');
    },

    selectFile(e) {
      let fd = new FormData();
      const file = e.target.files[0];

      if (file.size / 1024 / 1024 > 2) {
        // return this.$toast('大小不能大于2M');
      }
      fd.append('picture', file);
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.$api.postImage(fd, { token: localStorage.getItem('token') }).then(
        (e) => {
          if (e.code === 0) {
            let params = {
              headImg: e.url,
              token: localStorage.getItem('token'),
            };
            this.$api.anyUserinfoedit(params).then(
              (res) => {
                toast.clear();
                if (res.code == 0) {
                  this.$toast.success('修改成功');
                  location.reload();
                }
              },
              (error) => {
                toast.clear();
                this.$toast(error.message);
              },
            );
          } else {
            toast.clear();
            this.$toast(e.msg);
          }
        },
        (error) => {
          toast.clear();
          this.$toast(error.message);
        },
      );
    },

    nameDie() {
      var mask = document.querySelector('.mask');
      mask.style.display = 'none';
      this.nickName = '';
    },
    // 修改昵称
    name() {
      if (this.nickName == '') {
        this.$toast.fail('请输入您的昵称');
        return;
      } else if (this.nickName == '/^[\u4e00-\u9fa5a-zA-Z-z0-9]+$/') {
        return;
      }
      let params = {
        nickName: this.nickName,
        token: localStorage.getItem('token'),
      };
      this.$api.anyUserinfoedit(params).then((res) => {
        if (res.code == 0) {
          this.$toast.success('修改成功');
          location.reload();
        } else {
          this.$toast(res.msg);
        }
      });
    },

    head() {
      let wrapper = document.querySelector('.wrapper');
      wrapper.style.display = 'none';
    },

    // 昵称遮罩层显示
    datum() {
      var mask = document.querySelector('.mask');
      mask.style.display = 'block';
    },

    //昵称遮罩层隐藏
    wrapper() {
      var mask = document.querySelector('.mask');
      mask.style.display = 'none';
    },
  },
};
</script>
<style lang="less" scoped>
//修改性别
.sex {
  width: 100%;
  position: absolute;
  bottom: 0;
}

//修改头像
.head {
  width: 100%;
  height: 205px;
  background-color: #ffffff;
  position: relative;
  top: 75%;
  font-size: 13px;
  line-height: 45px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .head_text {
    border-bottom: #f8f8f8 1px solid;
  }
}
// 修改昵称遮罩层
.modification {
  position: relative;
  border-radius: 5px;
  top: 220px;
  margin: 0 auto;
  width: 286px;
  height: 168px;
  background-color: #ffffff;
  text-align: center;
  .modification_name {
    font-size: 15px;
    line-height: 40px;
  }
  .modification_original {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }
  .input {
    width: 100%;
    input {
      height: 35px;
      padding-left: 26px;
      width: 260px;
      background-color: #f8f8f8;
      border: 0;
      font-size: 11px;
    }
  }
  .modification_but {
    padding-top: 20px;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    .but_cancel {
      width: 50%;
      text-align: center;
      color: #999999;
    }
    .but_confirm {
      width: 50%;
      text-align: center;
      border-left: 1px solid #999999;
      color: #0754d3;
    }
  }
}

.notarize {
  font-size: 13px;
  color: #0754d3;
}

#box {
  height: 100vh;
  background-color: #f3f4f8;
}
.box {
  width: 100%;
  padding-top: 21px;
  .box_one {
    width: 341px;
    margin-left: 17px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 17px;
  }
  .datum {
    display: flex;
    align-items: center;
    span {
      font-size: 13px;
      color: #666666;
    }
  }
  .datum_img {
    width: 7px;
    height: 12px;
    padding-left: 17px;
    padding-right: 10px;
  }
  .datum_head {
    width: 30px;
    height: 30px;
  }
  .one_datum {
    font-size: 13px;
    margin-left: 17px;
  }
}
</style>
